<template>
    <div>
        <div class="entry-short" v-bind:style="{ backgroundImage: 'url(' + cmsPageHeaderImage + ')' }">
            <div v-if="podcast" class="context">
                <h1>Podcast</h1>
            </div>
        </div>

        <section class="component-container" v-if="podcast">
            <div class="podcast-wrapper">
                
                <div class="podcast">
                    <div class="image">
                        <picture>
                            <template v-for="[format, set] of Object.entries(srcsets)">
                                <source v-if="format !== originalFormat" :srcset="set"
                                    sizes="(max-width: 1100px) 75vw, 40vw" :type="'image/' + format.slice(1)">
                            </template>
                            <img loading="lazy" decoding="async" :srcset="srcsets[originalFormat]"
                                :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 75vw, 40vw"
                                :alt="podcastImageAltTag">
                        </picture>
                    </div>
                    <div class="details">
                        <h2>{{ localize(podcast.attributes.title) }}</h2>
                        <div class="details-date">
                            <small>{{ dateFormat(podcast.attributes.date) }}</small>
                        </div>
                        <div class="details-text">
                            <p>{{ localize(podcast.attributes.description) }}</p>
                        </div>
                        <div class="details-services" v-if="podcast.attributes.appleLink || podcast.attributes.spotifyLink || podcast.attributes.deezerLink || podcast.attributes.podigeeUrl || this.oembedObject">
                            <h4>Listen on:</h4>
                            <div class="service-links">
                                <a v-if="podcast.attributes.appleLink" :href="podcast.attributes.appleLink" target="_blank"
                                    rel="noopener noreferrer">
                                    <img src="@/assets/icons/apple-podcast.svg" alt="Apple Podcasts" width="30px"
                                        height="30px" />
                                </a>
                                <a v-if="podcast.attributes.spotifyLink" :href="podcast.attributes.spotifyLink" target="_blank"
                                    rel="noopener noreferrer">
                                    <img src="@/assets/icons/spotify.svg" alt="Spotify Podcasts" width="30px"
                                        height="30px" />
                                </a>
                                <a v-if="podcast.attributes.deezerLink" :href="podcast.attributes.deezerLink" target="_blank"
                                    rel="noopener noreferrer">
                                    <img src="@/assets/icons/deezer.svg" alt="Deezer Podcasts" width="30px"
                                        height="30px" />
                                </a>
                                <a v-if="podcast.attributes.podigeeUrl" :href="podcast.attributes.podigeeUrl" target="_blank"
                                    rel="noopener noreferrer">
                                    <img src="@/assets/icons/podigee.svg" alt="Podigee Podcasts" width="30px"
                                        height="30px" />
                                </a>
                                <a v-if="this.oembedObject" :href="this.oembedObject.url" target="_blank"
                                    rel="noopener noreferrer">
                                    <img src="@/assets/icons/youtube.svg" alt="Watch on Youtube" width="30px"
                                        height="30px" />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <SpacerBlock :cmsBlock="{ spacing: 'small' }" />
                <PodcastPlayer :podcastCode="podcast.attributes.iFrameCode" :appleLink="podcast.attributes.appleLink" />
                <SpacerBlock :cmsBlock="{ spacing: 'small' }" />
                <LazyYoutube v-if="this.oembedObject" :src="this.oembedObject.url" thumbnailQuality="maxres" maxWidth="100%" :showTitle="true"/>
            </div>
        </section>

        <PageBuilder v-if="cmsPage" :cmsBlocks="cmsPage.data.attributes.cmsBlocks" />
    </div>
</template>

<script>
import { config } from '@/config'
import { podcastQuery, globalOptionQuery, generalSubContentQuery } from '@/queries'
import { dateFormat } from '@/helper'
import PageBuilder from '@/components/PageBuilder'
import { LazyYoutube } from 'vue-lazytube'


export default {
    name: 'Podcast',

    components: {
        PodcastPlayer: () => import('@/components/PageBuilder/Elements/PodcastPlayer'),
        SpacerBlock: () => import('@/components/PageBuilder/SpacerBlock'),
        LazyYoutube,
        PageBuilder
    },

    data() {
        return {
            cmsPage: null,
            podcast: null,
            route: this.$route
        }
    },

    apollo: {
        podcast: {
            query: podcastQuery,
            variables() {
                return {
                    slug: this.$route.params.slug
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.podcasts.data[0]
        },

        cmsPage: {
            query: generalSubContentQuery,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.generalSubContent
        },

        defaultCmsPageHeader: {
            query: globalOptionQuery,
            variables: {
                optionId: 'default-cms-page-header'
            },
            fetchPolicy: 'cache-first',
            update: data => data.globalOptions.data[0]
        }
    },

    metaInfo() {
        var meta = []

        if (this.metaTitle) {
            meta.push({
                vmid: 'og:title',
                name: 'og:title',
                content: this.metaTitle
            })
        }
        if (this.metaDescription) {
            meta.push({
                vmid: 'description',
                name: 'description',
                content: this.metaDescription
            })
            meta.push({
                vmid: 'og:description',
                name: 'og:description',
                content: this.metaDescription
            })
        }
        if (this.metaImage) {
            meta.push({
                vmid: 'og:image',
                name: 'og:image',
                content: this.metaImage
            })
        }

        return {
            title: this.metaTitle,
            meta: meta
        }
    },

    computed: {
        oembedObject() {
           return JSON.parse(this.podcast.attributes.youtubeVideo)
        },

        podcastImage() {
            if (this.podcast && this.localize(this.podcast.attributes.image).data.attributes.formats) {
                return this.getScaledImageUrl(this.localize(this.podcast.attributes.image).data.attributes)
            }
            return null
        },

        srcsets() {
            if (this.podcast && this.localize(this.podcast.attributes.image).data) {
                return this.getImageSrcsets(this.localize(this.podcast.attributes.image).data.attributes);
            }
            return null;
        },

        hasWebp() {
            return this.srcsets[".webp"] ? true : false;
        },

        originalFormat() {
            for (const [format, _] of Object.entries(this.srcsets)) {
                if (format !== ".webp") {
                    return format;
                }
            }
            return null;
        },

        podcastImageAltTag() {
            if (this.podcast && this.localize(this.podcast.attributes.image)) {
                return this.localize(this.podcast.attributes.image).data.attributes.alternativeText
            }
            return "Podcast Cover Image"
        },

        cmsPageHeaderImage() {
            return this.defaultCmsPageHeader ? this.getImageUrl(this.localize(this.defaultCmsPageHeader.attributes.image).data.attributes.url) : ''
        },

        metaTitle() {
            if (this.podcast && this.localize(this.podcast.attributes.title)) {
                return config.globalPageTitle + ' Podcast - ' + this.localize(this.podcast.attributes.title)
            }

            return config.globalPageTitle + ' - ' + 'Podcast'
        },

        metaDescription() {
            if (this.podcast && this.localize(this.podcast.attributes.description)) {
                return this.localize(this.podcast.attributes.description)
            }
            return null
        },

        metaImage() {
            if (this.podcast && this.localize(this.podcast.attributes.image)) {
                return this.getImageUrl(this.localize(this.podcast.attributes.image).data.attributes.url)
            }
            return this.getAssetUrl(require('@/assets/placeholder.jpeg'))
        },
    },

    methods: {
        dateFormat: dateFormat,
    }
}
</script>

<style lang="scss" scoped>
.component-container {
    padding: getSpacing('padding-default-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');

    @include breakpoint('tablet') {
        padding: getSpacing('padding-default-vertical', 'tablet') getSpacing('padding-default-horizontal', 'tablet');
    }

    @include breakpoint('mobile') {
        padding: getSpacing('padding-default-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
    }

    .podcast-wrapper {
        max-width: $container-width;
        margin: 0 auto;

        .podcast {

            display: flex;
            flex-direction: row;
            gap: getSpacing('padding-default-horizontal', 'desktop');

            @include breakpoint('tablet') {
                gap: getSpacing('padding-default-horizontal', 'tablet');
            }

            @include breakpoint('mobile') {
                flex-direction: column;
            }

            .image {
                width: 35%;

                @include breakpoint('mobile') {
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .details {
                width: 65%;

                @include breakpoint('mobile') {
                    width: 100%;
                }

                h2 {
                    text-align: left;
                }

                ::v-deep p {
                    text-align: left;
                    font-size: getFontSize('smallText', 'desktop');

                    @include breakpoint('tablet') {
                        font-size: getFontSize('smallText', 'tablet');
                    }

                    @include breakpoint('mobile') {
                        font-size: getFontSize('smallText', 'mobile');
                    }
                }

                .details-services {
                    h4 {
                        text-align: left;
                    }
                    .service-links {
                        display: flex;
                        flex-direction: row;
                        gap: 12px;
                    }
                }
            }


        }
    }
}
</style>
